import { Scalapay } from '../interfaces/scalapay';
import { TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';
import { Preventive } from '../interfaces/preventive';
import { CustomerCompleteInfo, Invoice } from '../interfaces/user';
import { Product, StripeInfo } from '../interfaces/product';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PaymentMethod } from '../interfaces/payment-method';
import { Observable, throwError } from 'rxjs';
import { Admin } from '../interfaces/admin';
import { Coupon } from '../interfaces/coupon';
import moment from 'moment';
import { catchError } from 'rxjs/operators';
import { createCustomHeader } from '../util/http.util';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(
    private http: HttpClient,
    private translateService: TranslocoService,
    private toast: ToastrService
  ) {}

  getProducts(): Observable<Array<Product>> {
    return this.http.get<Array<Product>>(environment.be_url + '/payments/products/');
  }

  getPreventive(product_uid: string, coupon?: string): Observable<Preventive> {
    return this.http.get<Preventive>(
      environment.be_url + '/payments/customers/buy',
      createCustomHeader({
        target_resource: product_uid,
        custom_params: { Coupon: coupon || '' },
      })
    );
  }

  buy(
    product_uid: string,
    settings: { payment_method?: string; coupon?: string }
  ): Observable<{
    latest_invoice: string;
    default_payment_method: string;
  }> {
    const custom_params: { [key: string]: string } = {};
    if (settings.payment_method) custom_params['Payment-Method'] = settings.payment_method;
    if (settings.coupon) custom_params['Coupon'] = settings.coupon;

    return this.http.post<{
      latest_invoice: string;
      default_payment_method: string;
    }>(
      environment.be_url + '/payments/customers/buy',
      {},
      createCustomHeader({
        target_resource: product_uid,
        custom_params: custom_params,
      })
    );
  }

  buyScalapay(product_uid: string, settings: { coupon?: string }): Observable<Scalapay> {
    const custom_params: { [key: string]: string } = {};

    if (settings.coupon) custom_params['Coupon'] = settings.coupon;

    return this.http.post<Scalapay>(
      environment.be_url + '/payments/scalapay/buy',
      {},
      createCustomHeader({
        target_resource: product_uid,
        custom_params: custom_params,
      })
    );
  }

  captureScalapayOrder(scalapay_token: string): Observable<{ url: string }> {
    return this.http.post<{ url: string }>(
      environment.be_url + '/payments/scalapay/capture',
      {},
      createCustomHeader({
        target_resource: scalapay_token,
      })
    );
  }

  payInvoice(invoice_uid: string, payment_method?: string): Observable<Invoice> {
    return this.http.post<Invoice>(
      environment.be_url + '/payments/customers/invoice/pay',
      {},
      createCustomHeader({
        target_resource: invoice_uid,
        custom_params: { 'Payment-Method': payment_method || 'bank_transfert' },
      })
    );
  }

  getInvoiceInfo(invoice_uid: string): Observable<Invoice> {
    return this.http.get<Invoice>(
      environment.be_url + '/payments/customers/invoice',
      createCustomHeader({
        target_resource: invoice_uid,
      })
    );
  }

  getCustomer(): Observable<CustomerCompleteInfo> {
    return this.http.get<CustomerCompleteInfo>(environment.be_url + '/payments/customers/');
  }

  addPaymentMethod(card_number: string, expire_month: number, expire_year: number, cvv: string): Observable<PaymentMethod> {
    return this.http.put<PaymentMethod>(environment.be_url + '/payments/customers/payment_method', {
      number: card_number,
      exp_year: expire_year,
      exp_month: expire_month,
      cvc: cvv,
    });
  }

  public setPaymentMethodAsDefault(payment_method_id: string): Observable<void> {
    return this.http.post<void>(
      environment.be_url + '/payments/customers/payment_method',
      { favorite: true },
      createCustomHeader({
        target_resource: payment_method_id,
      })
    );
  }

  downloadInvoice(invoice: Invoice): void {
    const link = document.createElement('a');
    link.href = invoice.invoice_pdf;
    link.download = invoice.number + '.pdf';
    link.dispatchEvent(new MouseEvent('click'));
  }

  deletePaymentMethod(payment_method_id: string): Observable<void> {
    return this.http.delete<void>(
      environment.be_url + '/payments/customers/payment_method',
      createCustomHeader({
        target_resource: payment_method_id,
      })
    );
  }

  getAffiliatedAdmins(): Observable<{ tutors: Array<Admin>; customer_cares: Array<Admin> }> {
    const url = environment.be_url + '/payments/customers/affiliated_admins';
    return this.http.get<{
      tutors: Array<Admin>;
      customer_cares: Array<Admin>;
    }>(url);
  }

  getUserReferral(): Observable<Coupon> {
    return this.http.get<Coupon>(environment.be_url + '/payments/coupons/user');
  }

  productDuration(product: Product): number {
    if (!product.content.end_date) return 0;
    if (!product.content.subscription) return 0;

    const start = moment(product.content.start_date || new Date());
    const end = moment(product.content.end_date);

    return end.diff(start, product.content.subscription.interval);
  }

  moduleFilled(deal_id: number): Observable<void> {
    return this.http.post<void>(`${environment.be_url}/payments/subscriptions/module_filled`, { deal_id });
  }

  dealSubscriptionStatus(deal_id: string): Observable<StripeInfo> {
    return this.http.get<StripeInfo>(
      environment.be_url + '/payments/subscriptions/deal_status',
      createCustomHeader({
        custom_params: { Deal: deal_id },
      })
    );
  }

  activateCoupon(code: string, product?: string): Observable<Coupon> {
    const custom_params: { [key: string]: string } = {};
    if (product) custom_params['Product'] = product;

    return this.http
      .get<Coupon>(
        environment.be_url + '/payments/coupons/activate',
        createCustomHeader({
          target_resource: code,
          custom_params,
        })
      )
      .pipe(
        catchError(err => {
          const error = err.error;
          const ERROR_TYPE = {
            'coupon.doesnt_exist': 'product.coupon.errors.doesnt_exists',
            'coupon.not_valid_for_product': 'product.coupon.errors.not_valid_for_product',
            'coupon.max_redemptions_reached': 'product.coupon.errors.max_redemptions_reached',
            'coupon.expired': 'product.coupon.errors.expired',
            'coupon.owner': 'product.coupon.errors.owner',
          };
          localStorage.removeItem('coupon');
          this.toast.error(this.translateService.translate((ERROR_TYPE as Record<string, string>)[error.error] || 'product.coupon.errors.general'), '', {
            progressBar: true,
            closeButton: true,
            positionClass: 'toast-bottom-right',
            timeOut: 3000,
          });
          return throwError(() => new Error(err));
        })
      );
  }
}
